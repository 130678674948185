@media screen and (max-width: 1550px) {
  .login-tabs-container h3, .login-tabs-container .h3{  
      line-height: 30px;
    }
  .custom-margin {
    margin-top: 7px !important;
  }
}
@media screen and (max-width: 1920px) and (min-width:1550px) {
  label {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px!important;
    color: #4E5154;
  }
  body, p {
    font-size: 12px;
    line-height: 26px;
    font-weight: 400; 
  }
}

@media (min-width: 1199px) and (max-width:1400px) and (max-height: 850px)  {
  
  .custom-container {
    max-width: 1100px!important;
  }
}
@media (min-width: 1199px) and (max-width:1400px) and (max-height: 767px)  {
  .login-tabs-container {
    padding: 0px!important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom-container {
    max-width: 850px!important;
  }
  .login-div .copyright-bottom {
    margin-top: 19px;
  }
  .login-div .login-top-logo {
    margin: 0 0 15px 0px;
  }
  .login-div .card-header .card-title h4 {
    font-size: 24px;
    color: #000000;
  }
  .login-div .btn {
    font-size: 12px;
  }
  .login-div .login-top-logo .logo-block .img-tp {
    min-width: 160px;
  }
  .logo-block {
    text-align: center;
  }
  .react-calendar__year-view ,.react-calendar__month-view {
    max-height: 150px;
    overflow: scroll;
  }
}

@media screen and (max-width:1299px) {
  .login-div .card-header .card-title h4 {
    font-size: 34px;
   }
}


@media screen and (max-width:1200px) {
  .vertical-layout .app-content .header-navbar.floating-nav {
    width: calc(100% - 0rem) !important;
  }
  .login-div .login-top-logo {
    margin: 0 0 50px 0px;
  }
  .login-div .card-header .card-title h4 {
    font-size: 34px; 
  }
  .login-div .copyright-bottom {
    margin-top: 50px;
  }
}
@media screen and (max-width: 991px) {
  :root {
    --es-h3: 16px;
    --es-h5: 13px;

  }
  .ReactTable .rt-th, .ReactTable .rt-td {
    font-size: 12px;
  } 
  .ReactTable .rt-thead .rt-th,.ReactTable .rt-thead .rt-td {
    padding: 5px 5px !important;
  }
  .ReactTable .rt-th,.ReactTable .rt-td {
    padding: 5px 5px!important;
  }


}
@media screen and (max-width: 767px) {
  :root {
    --es-h1: 24px;
    --es-h2: 20px;
    --es-h3: 16px;
    --es-h4: 14px;
    --es-h5: 13px;
    --es-h6: 12px;
    --es-body-font-size: 12px;
    --es-radius: 15px;
  }
  /*header block */
  .header-navbar .navbar-container ul.nav li a.dropdown-user-link {
    padding: 0.7rem 5px;
  }
  .title-block {
    padding: 0 0 15px 0px;
  }
  .double-breadcumb .title-block {
    padding: 0 0 0px 0px;
  }
  .vx-collapse .collapse-title {
    font-size: 14px;
  }
  /*login page css started*/
  .login-div .content-block .auth-title{
    padding-left: 0px!important;
  }
  .login-div .card-body{
    padding: 0px;
  }
  .login-div .card-header{
    padding-left: 0px;
  }
  .login-tabs-container {
    height: 350px;
    min-height: 350px!important;
  }
  .login-tabs-container h3, .login-tabs-container .h3{  
    line-height: 22px;
  }
  li.dropdown-user.nav-item.dropdown {
    min-width: auto;
  }
  .header-navbar .navbar-container{
    padding-left: 0px;
  }
  .calendar-btn .btn {
    height: 38px;
  }
  
  .login-div .login-top-logo {
    margin: 0 0 25px 0px;
    text-align: center;
  }
  .login-div .card-header .card-title h4 {
    font-size: 24px;
  }
  .login-div .copyright-bottom {
    margin-top: 30px;
  }
  .login-div .login-top-logo .logo-block .img-tp {
    min-width: 150px;
  }
  .blank-page .content-wrapper .flexbox-container {
    display: block!important;
    height: 100%!important;
    padding: 15px!important;  
  }

  .add-new-branches .branch-services .branch-services-list-block .list-element .active-service-title {
    display: block;
  }
  .add-new-branches .branch-services .branch-services-list-block .list-element .service-toggle {
    margin-top: 0 !important;
  }
 
}

@media screen and (max-width: 575px) {
  .btn {
    padding: 10px 10px;
    font-size: 14px;
  }
  .add-new-branches .btn{
    min-width: 80px !important;
  }
  .appointment label{
    margin: 10px 0px !important;
  }
  .title-block .btns-block {
    margin: 0 auto;
  }
  .double-breadcumb .title-block .btns-block {
    margin: 0;
    margin-left: 0;
  }
  .single-breadcumb , .double-breadcumb{  
    padding: 0px 0px 22px 0px;
  }
  .single-breadcumb .title-block .btns-block {
    margin-left: auto;
    margin-right: 0px;
  }
  .double-breadcumb .btns-block .react-ripples {
    margin-right: 10px;
  }
  
  .double-breadcumb .btns-block .btn {
    margin: 0px !important;
  }
  .title-block .btns-block .btn {
    margin-top: 10px;
  }
  .add-new-branches .day-list-main .day-list li {
    border-bottom: 1px solid;
    margin-bottom: 10px;
  }
  .add-new-branches .day-list-main .day-list li .day {
    margin-top: 0px;
  }
  .add-new-branches .day-list-main .day-list li:first-child .alter-work-day ,.add-new-branches .day-list-main .day-list li .alter-work-day {
    display: block;
    margin-top: 14px;
  }
  .add-new-branches .day-list-main .day-list li .alert-switch {
    margin-top: 0px;
  }
  .add-new-branches .location .location-map-block .map-view {
    height: 300px;
  }
  .add-new-branches .branch-services .branch-services-list-block .list-element:first-child .active-service-title {
    margin-top: 15px;
  }
  .add-new-branches .branch-services .branch-services-list-block .list-element {
    margin-bottom: 20px;
    border-bottom: 1px solid;
  }
  .add-new-branches .branch-services .services-list .form-group{
    margin-bottom: 15px!important;
  }
  
  .add-new-branches .branch-services .branch-services-list-block .list-element .service-toggle ,.add-new-branches .branch-services .branch-services-list-block .list-element:first-child .service-toggle {
    margin-top: 0px;
  }
  .add-new-branches .branch-services .branch-services-list-block .list-element:first-child .active-service-title {
    margin-top: 0px;
  }
  label {
    font-size: 12px;
  }
  /*login page css started*/
  .login-div .content-block .auth-title{
    padding-left: 0px!important;
  }
  .login-div .card-body{
    padding: 0px;
  }
  .login-div .card-header{
    padding-left: 0px;
  }
  .login-tabs-container {
    height: auto;
    min-height: auto!important;
  }
  .ag-grid-table .sort-dropdown {
    margin-bottom: 1rem;
  }
  .double-breadcumb .title-block{
      align-items: flex-start !important; 
  }
  .upload-image-list .div-blk-img {
    width: 100px;
    height: 80px;
  }
  .vertical-layout .app-content .header-navbar.floating-nav {
    width: calc(100% - 0rem) !important;
  }
  .header-breadcum{
    max-width: 150px;
    margin-left: 7px;
    
  }
  .header-breadcum .breadcrumb-separators svg{
    height: 16px;
    line-height: 15px;
    vertical-align: middle;
  }
  .blank-page .content-wrapper .flexbox-container {
    display: flex!important;
    height: 100vh!important;
    padding: 0!important;  
  }
 
}

@media (min-width: 769px) and (max-width: 825px) and (max-height: 400px)  {
   /*login page css started*/
  .login-tabs-container {
    min-height: 100%!important;
    height:100%!important;
  }
  .login-div {
    height: 250px;
    overflow: auto;
    overflow-x: hidden;
    display: block;
  }
   .login-div .d-block {
    margin: 0px 0px;
  }
}



@media screen and (max-height: 325px) {
   /*login page css started*/
  .login-div {
    height: 250px;
    overflow: auto;
    overflow-x: hidden;
    display: block;
  }
   .login-div .d-block {
    margin: 0px 0px;
  }
}
@media screen and (max-width: 420px) {
  .dashboard{
    padding: 0px;
  }
  .single-breadcumb, [dir] .double-breadcumb{
    padding: 0px 0px 25.5px 0px !important;
  }
  .ag-dropdown.p-1.mb-1.mb-sm-0.dropdown{
    margin-bottom: 10px !important;
  }
  /* .table-input{
    max-width: 150px;
  } */
}



